import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  submit() {
    var stripe = Stripe(document.querySelector('meta[name="stripe-key"]').content);

    fetch(this.data.get('session-url'), {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        "X-CSRF-Token": Rails.csrfToken()
      },
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(response) {
      if (response.id) {
        return stripe.redirectToCheckout({ sessionId: response.id });
      }
      else if (response.free_trial) {
        window.location.reload(true)
      }
    })
    .then(function(result) {
      if (result.error) {
        alert(result.error.message);
      }
    })
    .catch(function(error) {
      console.error('Error:', error);
    });
  }

}

