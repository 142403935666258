import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "value" ]

  mount() {
    this.valueTarget.value = 1
  }

  increase() {
    var newValue = Number(this.valueTarget.value) + 1

    if (newValue <= 10) {
      this.valueTarget.value = newValue;
    }
  }

  decrease() {
    var newValue = Number(this.valueTarget.value) - 1

    if (newValue >= 1) {
      this.valueTarget.value = newValue;
    }
  }

}

