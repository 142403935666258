require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import 'bootstrap/dist/css/bootstrap.min.css';
import 'cookies-eu-banner/css/cookies-eu-banner.default.css';

import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import CookiesEuBanner from 'cookies-eu-banner';

const application = Application.start()
const controllers = require.context("../controllers", true, /\.js$/)

application.load(definitionsFromContext(controllers));

document.addEventListener('turbo:load', () => {
  new CookiesEuBanner(function () {
    if (typeof(mixpanel) !== "undefined") {
      mixpanel.track("Page Open");
    }
  }, true);

});
