import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "copyText" ]

  copy(event) {
    event.preventDefault()
    const cb = navigator.clipboard;
    const paragraph = this.copyTextTarget;
    cb.writeText(paragraph.innerText);
  }
}
