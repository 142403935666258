import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  setFilename(evt) {
    evt.target.parentElement.querySelector('span').textContent = evt.target.files[0].name;
  }

}

